import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Nie znaleziono" />
    <h1>Nie znaleziono strony</h1>
  </Layout>
)

export default NotFoundPage
